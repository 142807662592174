import React from 'react';
import SEO from '../components/seo';

import MedicImg from '../components/images/projects/medic';
import Partners from '../assets/svgs/partners/insignia_pt2020_norte2020-01.svg';

import './portugal2020.css';

const Portugal2020 = () => (
  <>
    <SEO title="Portugal2020" />
    {/* Projects */}
    <section className="w-full flex flex-row justify-center px-screen-xs sm:px-screen-sm md:px-screen-md lg:px-screen-lg xl:px-screen-xl my-24">
      <div className="w-full xl:w-2/3 flex flex-col justify-center">
        <div className="w-full border border-light-gray shadow-2xl">
          {/* Partners */}
          <div className="flex justify-center w-full my-2">
            <img
              src={Partners}
              id="partnersImg"
              className="w-full mx-6"
              alt="partner"
            />
          </div>
        </div>
        <div className="w-full flex flex-col md:flex-row">
          <div className="w-full md:w-2/3 border border-light-gray shadow-2xl">
            {/* Title */}
            <div className="w-full my-12">
              <h2 className="text-center text-xs-h5 sm:text-sm-h5 md:text-md-h5 lg:text-md-h5">
                LiNEFORYOU, LDA
              </h2>
            </div>
            {/* Descriptions */}
            <div className="mx-12 md:mx-24 my-12">
              <div className="text-left text-dark-gray text-xs-p my-8">
                <p>
                  <b>Designação do projeto:</b> InovLINEFORYOU
                </p>
                <p>
                  <b>Código do projeto:</b> NORTE-02-08B9-FEDER-048350
                </p>
                <p>
                  <b>Objetivo principal:</b> Reforçar a competitividade das
                  pequenas e médias empresas
                </p>
                <p>
                  <b>Região de intervenção:</b> NORTE
                </p>
                <p>
                  <b>Entidade beneficiária:</b> LINEFORYOU, Lda.
                </p>
              </div>
              <div className="text-left text-dark-gray text-xs-p my-8">
                <p>
                  <b>Data de aprovação:</b> 14-05-2020
                </p>
                <p>
                  <b>Data de início:</b> 19-05-2020
                </p>
                <p>
                  <b>Data de conclusão:</b> 15-11-2020
                </p>
                <p>
                  <b>Custo total elegível:</b> 330.617,50€
                </p>
                <p>
                  <b>Apoio financeiro da União Europeia:</b> FEDER – 264.494,00€
                </p>
              </div>
              <div className="text-dark-gray text-xs-p my-8 text-justify">
                <p>
                  <b>Objetivos:</b> Adaptação e implementação de uma linha de
                  produção para o desenvolvimento de dispositivos médicos
                  reutilizáveis e descartáveis e EPI’s – máscaras de uso geral
                  para o combate à Covid-19.
                </p>
                <p>
                  <b>Resultados Esperados:</b> Produção e comercialização de 13
                  dispositivos médicos (DM) e 2 Equipamentos de Protecção
                  Individual (EPI's).
                </p>
              </div>
            </div>
          </div>
          <div
            id="medicImg"
            className="w-full md:w-1/3 lg:h-906 md:h-906 sm:h-512"
          >
            <MedicImg className="h-full w-full" />
          </div>
        </div>
      </div>
    </section>
  </>
);

export default Portugal2020;
